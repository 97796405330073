import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SoftOne from "../images/partners/softone.webp"
import Lexmark from "../images/partners/lexmark.webp"
import Elzab from "../images/partners/elzab.webp"
import Rbs from "../images/partners/rbs.webp"
import Panda from "../images/partners/panda.webp"
import Carousel1 from "../images/carousel/1.webp"
import Carousel2 from "../images/carousel/2.webp"
import Carousel3 from "../images/carousel/3.webp"
import Carousel4 from "../images/carousel/4.webp"

const IndexPage = () => (
  <>
    <Layout>
      <SEO title="Αρχική" />
      <div
        id="carouselExampleDark"
        className="carousel slide"
        data-ride="carousel"
      >
        <ol className="carousel-indicators">
          <li
            data-target="#carouselExampleDark"
            data-slide-to="0"
            className="active"
          ></li>
          <li data-target="#carouselExampleDark" data-slide-to="1"></li>
          <li data-target="#carouselExampleDark" data-slide-to="2"></li>
          <li data-target="#carouselExampleDark" data-slide-to="3"></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active" data-interval="10000">
            <img src={Carousel1} className="d-block w-100" alt="People"/>
            <div className="carousel-caption d-none d-md-block">
              <h5>Φτιαγμένο από ανθρώπους, για ανθρώπους.</h5>
              <p>
                Με πάνω από τρεις δεκαετίες εμπειρίας στεκόμαστε δίπλα σου για
                πάμε μαζί την επιχείρησή σου ένα βήμα μπροστά από τον
                ανταγωνισμό.
              </p>
            </div>
          </div>
          <div className="carousel-item" data-interval="10000">
            <img src={Carousel2} loading="lazy" className="d-block w-100" alt="Lines of code"/>
            <div className="carousel-caption d-none d-md-block">
              <h5>Δεν θα βρεθείς ποτέ σε αδιέξοδο!</h5>
              <p>
                Η έμπειρη και άρτια καταρτισμένη ομάδα μας βρίσκεται πάντα δίπλα
                σας για να φτιάξουμε μια λύση ειδικά για εσάς.
              </p>
            </div>
          </div>
          <div className="carousel-item" data-interval="10000">
            <img src={Carousel3} loading="lazy" className="d-block w-100" alt="A building"/>
            <div className="carousel-caption d-none d-md-block">
              <h5 className="text-dark">Η επιχείρησή σου στα… σύννεφα!</h5>
              <p className="text-dark">
                Με την δύναμη και την αξιοπιστία του Azure μπορείς να δουλέψεις
                από παντού χωρίς διακοπές.
              </p>
            </div>
          </div>
          <div className="carousel-item" data-interval="10000">
            <img src={Carousel4} loading="lazy" className="d-block w-100" alt="A woman writing to paper"/>
            <div className="carousel-caption d-none d-md-block">
              <h5 className="text-dark">
                Πάρε τον έλεγχο της επιχείρησής σου στα χέρια σου.
              </h5>
              <p className="text-dark">
                Με προηγμένα εργαλεία reporting μην αφήσεις τίποτα στα… άγραφα!
              </p>
            </div>
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleDark"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleDark"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </a>
      </div>

      {/* <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light">
        <div className="col-md-5 p-lg-5 mx-auto my-5">
          <h1 className="display-4 font-weight-normal">Punny headline</h1>
          <p className="lead font-weight-normal">And an even wittier subheading to boot. Jumpstart your marketing efforts with this example based on Apple's marketing pages.</p>
          <Link to="/" className="btn btn-outline-secondary" role="button">Μάθετε περισσότερα</Link>
        </div>
      </div> */}

      <div className="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3">
        <div className="bg-dark mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden">
          <div className="my-3 py-3">
            <h1 className="display-5">
              Startups&nbsp;<i className="fas fa-user"></i>
            </h1>
            <p className="lead">
              Αναλύουμε εκτενώς τα ιδιαίτερα χαρακτηριστικά της αγοράς-στόχου
              σας, καθώς και του εσωτερικού περιβάλλοντος, αξιολογούμε πιθανές
              ευκαιρίες, κενά και προκλήσεις. Με αυτόν τον τρόπο, καλλιεργούμε
              το κατάλληλο έδαφος, ώστε να τοποθετηθείτε δυναμικά σε σχέση με
              τον ανταγωνισμό και να αναπτύξετε τα ισχυρά σημεία σας.
            </p>
          </div>
          <Link to="/startups" className="btn btn-secondary mb-5" role="button">
            Μάθετε περισσότερα
          </Link>
        </div>
        <div className="bg-light mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
          <div className="my-3 p-3">
            <h1 className="display-5">
              Μικρομεσαίες επιχειρήσεις&nbsp;
              <i className="fas fa-user-friends"></i>
            </h1>
            <p className="lead">
              Με μοναδική κατεύθυνση την ταχεία και αποτελεσματική διαχείριση
              δεδομένων, είμαστε δίπλα σας να διευρύνουμε τις αναπτυξιακές
              προοπτικές σας ώστε να εστιάσετε στο επόμενο βήμα.
            </p>
          </div>
          <Link to="/mikromesaies-epixeiriseis" className="btn btn-secondary mb-5" role="button">
            Μάθετε περισσότερα
          </Link>
        </div>
      </div>

      <div className="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3">
        <div className="bg-light mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
          <div className="my-3 p-3">
            <h1 className="display-5">
              Μεγάλες Επιχειρήσεις&nbsp;<i className="fas fa-users"></i>
            </h1>
            <p className="lead">
              Απογειώστε τη δυναμική σας, εξασφαλίζοντας κάθε απαραίτητο εφόδιο
              για την επιχειρηματική σας πρόοδο με τα ολοκληρωμένα συστήματα που
              προσφέρουμε, όπως και ειδικά πρόσθετα φτιαγμένα αποκλειστικά για
              εσάς και τις ανάγκες σας τα οποία θα σας λύσουν τα χέρια.
            </p>
          </div>
          <Link to="/megales-epixeiriseis" className="btn btn-secondary mb-5" role="button">
            Μάθετε περισσότερα
          </Link>
        </div>
        <div className="bg-dark mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden">
          <div className="my-3 py-3">
            <h1 className="display-5">
              Λογιστικές Εφαρμογές &#38; Μισθοδοσία&nbsp;
              <i className="fas fa-calculator"></i>
            </h1>
            <p className="lead">
              Με φιλικό περιβάλλον εργασίας και εξειδικευμένες λειτουργίες όπως
              φοροτεχνικές εργασίες με το πάτημα ενός κουμπιού, απλοποιήστε
              πολύπλοκες καθημερινές εργασίες και αυξήστε την παραγωγικότητά
              σας, εξοικονομώντας καθημερινά χρόνο και χρήματα.
            </p>
          </div>
          <Link to="/logistikes-efarmoges-kai-misthodosia" className="btn btn-secondary mb-5" role="button">
            Μάθετε περισσότερα
          </Link>
        </div>
      </div>

      <div className="row w-100 mt-4">
        <div className="col">
          <div className="text-center">
            <h2>Οι συνεργάτες μας</h2>
            <a
              href="https://www.softone.gr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="img-fluid partner"
                src={SoftOne}
                loading="lazy"
                alt="SoftOne"
                width="150"
              />
            </a>
            <a
              href="https://www.lexmark.com/el_gr.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="img-fluid partner"
                src={Lexmark}
                loading="lazy"
                alt="Lexmark"
                width="150"
              />
            </a>
            <a
              href="https://www.elzabhellas.gr/el/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="img-fluid partner"
                src={Elzab}
                loading="lazy"
                alt="Elzab"
                width="150"
              />
            </a>
            <a
              href="https://www.rbs.gr/el/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="img-fluid partner"
                src={Rbs}
                loading="lazy"
                alt="Rbs"
                width="150"
              />
            </a>
            <a
              href="https://www.pandasecurity.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="img-fluid partner"
                src={Panda}
                loading="lazy"
                alt="Panda"
                width="150"
              />
            </a>
          </div>
        </div>
      </div>
    </Layout>
  </>
)

export default IndexPage
